import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { rolesServices } from "../../services/rolesServices";

@Component
export default class RoleClaims extends Vue {
  @Prop({ default: null })
  role: role.item;

  claims: Array<role.claim> = [];
  roleClaims: Array<role.roleClaim> = [];

  async created() {
    await this.fetchData();
  }

  @Watch("role", { deep: true })
  async chaneRole(n, o) {
    if (n && n != o) {
      await this.fetchData();
    }
  }
  
  getRoleClaim(claimType: string): role.roleClaim {
    return this.roleClaims?.find((rc) => rc.type === claimType);
  }

  loading: boolean = false;
  async fetchData(){
    this.loading = true;
    this.claims = await rolesServices.getAllClaims();
    this.roleClaims = await rolesServices.getRoleClaims(this.role.name);
    this.loading = false;
  }

  get groupedClaims() {
    var mp = new Map<string, role.claim[]>();
    for (const c of this.claims) {
      var g = c.type.substring(0, c.type.replace("-", ".").indexOf("."));
      if (!mp.has(g)) {
        mp.set(g, []);
      }

      mp.get(g).push(c);
    }

    return Array.from(mp.entries());
  }

  async toggleRoleClaim(claimType: string) {
    let claim = this.getRoleClaim(claimType);

    if (!claim) {
      this.roleClaims = await rolesServices.createNewRoleClaim(this.role.name, claimType);
    } else {
      let idx = this.roleClaims.indexOf(claim);
      this.roleClaims.splice(idx, 1);
      await rolesServices.deleteRoleClaim(this.role.name, claim.type);
    }
  }

  allClaimsInGroup(claims: role.claim[]): boolean {
    for (const claim of claims) {
      if (!this.getRoleClaim(claim.type)) return false;
    }
    return true;
  }

  async toggleAllClaimsInGroups(claims: role.claim[], value) {
    for (const claim of claims) {
      let current = this.getRoleClaim(claim.type);
      if (value && !current) {
        await rolesServices.createNewRoleClaim(this.role.name, claim.type);
      }

      if (!value && current) {
        await rolesServices.deleteRoleClaim(this.role.name, claim.type);
      }
    }

    this.fetchData();
  }
}
