import { render, staticRenderFns } from "./rolesView.vue?vue&type=template&id=a420d5cc"
import script from "./rolesView.ts?vue&type=script&lang=ts&external"
export * from "./rolesView.ts?vue&type=script&lang=ts&external"
import style0 from "./rolesView.vue?vue&type=style&index=0&id=a420d5cc&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports