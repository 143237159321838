import Vue from "vue";
import Component from "vue-class-component";
import rolesList from '../components/rolesList/rolesList.vue'
import roleClaims from "../components/roleClaims/roleClaims.vue";
import { rolesServices } from "../services/rolesServices";
import roleUsers from "../components/roleUsers/roleUsers.vue";

@Component({
  components: {
    rolesList,
    roleClaims,
    roleUsers,
  }
})

export default class rolesView extends Vue {
  selectedRole: role.item = null;
}