import { Vue, Component, Prop } from "vue-property-decorator";
import { rolesServices } from "../../services/rolesServices";

@Component({})

export default class rolesList extends Vue {
  @Prop({ default: null })
  value!: role.item;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [15],
    total: 0
  }

  query: { page: number, take: number, filter: string } = {
    page: 1,
    take: 15,
    filter: null
  };

  roles: role.item[] = [];
  async created() {
    this.roles = await rolesServices.getRoles();
    this.pagination.total = this.roles.length;
  }

  get selectedRole(): role.item {
    return this.value;
  }
  set selectedRole(value: role.item) {
    this.$emit("input", value);
  }

  get filteredRoles() {
    if (this.roles.length <= 0) return [];
    return this.roles
      .filter((r) => !this.query.filter || (r.name.toLowerCase() || '').indexOf(this.query.filter.toLowerCase()) >= 0)
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice((this.query.page - 1) * this.query.take, (this.query.page * this.query.take))
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
  }

  paginationPageChange(page) {
    this.query.page = page;
  }

  rowClick(row: role.item) {
    this.selectedRole = row;
  }

  highlightCurrentRow({ row }: { row: role.item }) {
    if (row === this.selectedRole)
      return 'highlight-selected-row';
    return '';
  }

  handleAdd() {
    this.$prompt(`Crea un nuovo ruolo con cui aggregare i permessi`, `Aggiungi un nuovo ruolo`, {
      confirmButtonText: `Salva`,
      cancelButtonText: 'Annulla',
      inputValidator: this.validateRuolo,
      inputPlaceholder: 'Inserisci il nome del nuovo ruolo'
    }).then(({ value }: any) => {
      rolesServices.createNewRole(value)
        .then((role: role.item) => {
          this.roles.push(role);
          this.selectedRole = role;
          this.$message({
            message: `Il ruolo <strong>${role.name}</strong> è stato aggiunto con successo`,
            dangerouslyUseHTMLString: true,
            type: "success",
            duration: 5000,
          });
        }).catch((error) => {
          this.$message({
            message: `Si è verificato un errore nell'inserimento del ruolo <strong>${value}</strong>`,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: "error",
            duration: 5000,
          });
        });
    }).catch(() => { });
  }

  validateRuolo(value: string) {
    if (!value || value.replace(/\s+/, "").length <= 0)
      return `E' obbligatorio inserire il nome del ruolo`;
    if((this.roles || []).find(r => r.normalizedName.toUpperCase() == value.toUpperCase())){
      return `Esiste un ruolo con questo nome`;
    }
    return true;
  }

  async handleDelete(role: role.item) {
    try {
      this.$confirm(`Il ruolo verrà rimosso <strong>${role.name}</strong> definitivamente. Continuare?`, "Attenzione!", {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annulla',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(async () => {
        await rolesServices.deleteRole(role.name);
        const idx = this.roles.findIndex(f => f.id === role.id);
        if (idx >= 0) this.roles.splice(idx, 1);
        this.$message({
          message: `Il ruolo <strong>${role.name}</strong> è stato rimosso con successo`,
          dangerouslyUseHTMLString: true,
          type: "success",
        })
      })
    } catch (error) {
      this.$message({
        message: `Errore nella rimozione del ruolo <strong>${role.name}</strong>`,
        dangerouslyUseHTMLString: true,
        type: "error",
      })
    }


  }
}
