import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import userAutocomplete from "@/modules/users/components/userAutocomplete.vue";
import { rolesServices } from "../../services/rolesServices";

@Component({
  components: {
    userAutocomplete
  }
})

export default class RoleUsers extends Vue {
  @Prop()
  role: role.item;

  userId: string = ""

  usersPerRole: { id: string, userName: string }[] = [];
  async created() {
    await this.fetchData();
  }

  @Watch("role", { deep: true })
  async chaneRole(n, o) {
    if (n && n != o) {
      await this.fetchData();
    }
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.usersPerRole = (await rolesServices.getRoleUsers(this.role.name)).map(element => {
      return { id: element.id, userName: element.userName }
    })
    this.loading = false;
  }

  get list(): { id: string, userName: string }[] {
    return this.usersPerRole.sort((a, b) => a.userName.localeCompare(b.userName));
  }

  async handleAdd() {
    try {
      await rolesServices.assignRoleToUser(this.role.name, this.userId)
      this.$message({
        message: `Utente assegnato correttamente al ruolo <strong>${this.role.name}</strong>`,
        dangerouslyUseHTMLString: true,
        type: "success",
      })
      await this.fetchData();
      this.userId = null;
    } catch (error) {
      this.$message({
        message: `Errore nell'assegnazione dell'utente al ruolo <strong>${this.role.name}</strong>`,
        dangerouslyUseHTMLString: true,
        type: "error",
      })
    }
  }

  handleDelete(user: { id: string, userName: string }) {
    try {
      this.$confirm(`Vuoi rimuovere l'associazione dell'utente <strong>${user.userName}</strong><br>con il ruolo <strong>${this.role.name}</strong>?`, "Attenzione!", {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annulla',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(async () => {
        await rolesServices.removeUserFromRole(this.role.name, user.id)
        this.$message({
          message: `L'utente <strong>${user.userName}</strong> è stato rimosso con successo dal ruolo <strong>${this.role.name}</strong>`,
          dangerouslyUseHTMLString: true,
          type: "success",
        })
        await this.fetchData();
      })
    } catch (error) {
      this.$message({
        message: `Errore nella rimozione dell'utente <strong>${user.userName}</strong><br>dal ruolo <strong>${this.role.name}</strong>`,
        dangerouslyUseHTMLString: true,
        type: "error",
      })
    }
  }
}